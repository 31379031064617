import { InputText } from "primereact/inputtext";
import brand from  '../Assets/images/Branding.png'
import social from  '../Assets/images/SocialMedia.png'
import before from  '../Assets/images/BeforeAfter.png'
import photoshoot from  '../Assets/images/Photoshoots.png'
import videos from  '../Assets/images/Videos.png'
import st from  '../Assets/images/Stationery.png'
import training from  '../Assets/images/Training.png'
import Am from  '../Assets/images/Ambassadors.png'
import Qy from  '../Assets/images/QuestionsAnswers.png'
import catalog from  '../Assets/images/Catalog.png'

import '../styles/modaleta.scss'
type Props ={
    type: string
}

const Moldeate = (props:Props) =>{
return(
    <div className="" style={{overflow:'hidden'}}>
      <div className=" flex pt-5 gap-0 md:ml-8 lg:ml-8">
        <div className="md:col-5 lg:col-5 ml-7">
          <span className="p-input-icon-left">
            <i className="pi pi-user" />
            <InputText placeholder="Home" className='input-tx-login md:w-25rem	lg:w-25rem	'/>
          </span>
        </div>

        <div className="md:col-6 lg:col-6 flex justify-content-center ml-7">
          <span className="p-input-icon-left">
            {/* <i className="pi pi-search"  /> */}
            <InputText placeholder="Search" className='input-tx-login mr-8 md:w-30rem	lg:w-30rem' />
          </span>
        </div>
      </div>
      <div>
      <div className="pt-6 grid gap-5 justify-content-center  ">
        <div className="">
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
            <img src={brand} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Branding</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={social} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Social Media</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={before} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Before & After</h1>
          </div>
          </div>
          </div>
    
          <div className="pt-5 grid gap-5 justify-content-center ">
        <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
            <img src={photoshoot} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Photoshoot</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={videos} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Videos</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={st} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Stationery</h1>
          </div>
          </div>
          </div>

          <div className="pt-5 grid gap-5 justify-content-center ">
        <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
            <img src={training} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Training</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={Am} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Brand Ambassadors</h1>
          </div>
          </div>
          <div>
          <div className="mo_sty md:col-3 lg:col-3 md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <img src={Qy} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Questions / Answers</h1>
          </div>
          </div>
          </div>

          <div className="pt-5 pl-7 ml-7">
        <div>
          <div className="mo_sty md:w-24rem	lg:w-24rem" style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
            <img src={catalog} style={{width:'350px', height:'250px'}} />
          </div>
          <div className="mo_hd md:col-3 lg:col-3 md:w-24rem	lg:w-24rem " style={props.type === 'style' ? {backgroundColor:'#B4918F'}:
          props.type === 'care' ? {backgroundColor:'#B9ABBB'}:  props.type === 'med' ? {backgroundColor:'#4463AB'}:
          props.type === 'active' ? {backgroundColor:'#EA3B3A'}:props.type === 'men' ? {backgroundColor:'#334E58'}:
          {}}>
          <h1>Catalog</h1>
          </div>
          </div>
          </div>
      </div>
     
    </div>
)
}

export default Moldeate;