import { InputText } from 'primereact/inputtext';
import '../styles/login.scss'
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/images/logo.png'
const Login = () =>{
  const navigate = useNavigate()
    return(
        <div className="login-container flex" style={{overflow:'hidden'}}>
            <div className="login-circle"></div>
            <div className='login-form'>
            <div className="parent">
            <div className='parent-container'>
  <div>
    <img src={logo} className='background-main' alt="Company Logo" />
  </div>
  <div>
    <p className='header-tx-c'>Continue to Moldeate Support</p>
  </div>
</div>

            </div>
             <label className='header-tx'  style={{marginTop:'10px' , textAlign:'center' , marginBottom:'8px'}}> Username/Email</label>
             <InputText className='input-tx-login' type="text" id="username" name="username" required></InputText>
             <label className='header-tx' style={{marginTop:'10px' , textAlign:'center' , marginBottom:'8px'}} > Password</label>
             <InputText className='input-tx-login' type="text" id="Password" name="password" required></InputText>
             <Button className='button-login' onClick={()=>navigate('/Moldeate-list')} label='Login'></Button>
             <Button className='button-login-2' label='Log in as Moldeate Employee'></Button>
             <div className='flex md:gap-8 lg:gap-8'>
             <p className='footer-login-tx justify-content-start	'>Be Part of Moldeate Family</p>
             <p className='footer-login-tx-2 align-items-end		'>Forget password?</p>
             </div>
             <div className='flex justify-content-center	'>
             <Button icon="pi pi-shopping-cart" className="p-button-rounded cart-i mr-2 mt-2" disabled ></Button>
             <p className='footer-login-tx-3'>For More Details</p>
             </div>
             </div>
        </div>
    )

}

export default Login;