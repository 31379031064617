import { BrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import './App.css';
import LayoutComponent from './routes/Layout';

function App() {
  return (
    <BrowserRouter>
      <PrimeReactProvider>
      <LayoutComponent/>
      </PrimeReactProvider>
      </BrowserRouter>
  );
}

export default App;
