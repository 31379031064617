import { InputText } from "primereact/inputtext";
import '../styles/list.scss'
import { useNavigate } from "react-router-dom";
import logo from '../Assets/images/LogoWhite.png'
import { log } from "console";
const List = () => {
  const navigate = useNavigate();
  return (
    <div  style={{overflow:'hidden'}}>
      <div className=" flex pt-5 gap-0 md:ml-8 lg:ml-8">
        <div className="md:col-5 lg:col-5 ml-7">
          <span className="p-input-icon-left">
            <i className="pi pi-user" />
            <InputText placeholder="Home" className='input-tx-login md:w-25rem	lg:w-25rem	'/>
          </span>
        </div>

        <div className="md:col-6 lg:col-6 flex justify-content-center ml-7">
          <span className="p-input-icon-left">
            {/* <i className="pi pi-search"  /> */}
            <InputText placeholder="Search" className='input-tx-login mr-8 md:w-30rem	lg:w-30rem' />
          </span>
        </div>
      </div>
      <div>
      <div className="pt-6 grid gap-5 justify-content-center ">
          <div className="MOLDEATE md:col-3 lg:col-3">
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
          {/* <img src={m} /> */}
          </div>
          <div className="MOLDEATE1 md:col-3 lg:col-3" onClick={()=>navigate('Moldeate_style')}>
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
          <span className="text-center mt-0 font-semibold	">STYLE</span>
          </div>
          <div className="MOLDEATE2 md:col-3 lg:col-3" onClick={()=>navigate('Moldeate_care')}>
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
          <span className="text-center mt-0 font-semiboldt" >CARE</span>
          </div>
          </div>
          <div className="pt-6 grid gap-5 justify-content-center ">
          <div className="MOLDEATE3  md:col-3 lg:col-3" onClick={()=>navigate('Moldeate_med')}>
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
                    <span className="text-center mt-0 font-semibold" >MED</span> 
          </div>
          <div className="MOLDEATE4 md:col-3 lg:col-3"  onClick={()=>navigate('Moldeate_active')}>
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
        <span className="text-center mt-0 font-semibold" >ACTIVE</span> 

          </div>
          <div className="MOLDEATE5 md:col-3 lg:col-3"  onClick={()=>navigate('Moldeate_men')}>
          <img src={logo} style={{width:'250px', height:'100px' , zIndex:'10'}}/>
          <span className="text-center mt-0 font-semibold" >MAN</span>
          </div>
          </div>
      </div>
     
    </div>
  );
};

export default List;
