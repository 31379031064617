import { Route, Routes } from "react-router-dom"
import Login from "../pages/Login"
import "primereact/resources/themes/soho-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import List from "../pages/list";
import Moldeate from "../pages/Moldeate";

const LayoutComponent = () => {

    return(
        <div style={{height:'100vh'}}>
      <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/Moldeate-list" element={<List />}></Route>
      <Route path="/Moldeate-list/Moldeate_style" element={<Moldeate type="style" />}></Route>
      <Route path="/Moldeate-list/Moldeate_care" element={<Moldeate type="care"/>}></Route>
      <Route path="/Moldeate-list/Moldeate_med" element={<Moldeate type="med"/>}></Route>
      <Route path="/Moldeate-list/Moldeate_active" element={<Moldeate  type="active"/>}></Route>
      <Route path="/Moldeate-list/Moldeate_men" element={<Moldeate  type="men"/>}></Route>


        </Routes>
        </div>
    )
}
export default LayoutComponent